<template>
	<div class="mypage_content_wrap">
		<h2>쿠폰함</h2>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>쿠폰등록</h4>
				</div>
				<div class="coupon_register_wrap">
					<input v-model="coupon_integer" type="text" name="name" placeholder="쿠폰번호를 입력해주세요." />
					<button @click="coupon_regi()" type="button" class="coupon_register_btn">등록하기</button>
				</div>
				<div class="form_box_wrap">
					<ul class="caution">
						<li>쿠폰 번호는 공백 없이 입력해 주세요.</li>
						<li>쿠폰으로 충전된 캐시는 환불이나 양도가 불가능하오니 등록에 유의해 주세요.</li>
						<li>등록 기한이 지난 쿠폰은 사용이 불가능합니다.</li>
					</ul>
				</div>
			</div>
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>나의 쿠폰 리스트</h4>
					<div class="edit">편집</div>
					<div @click="checked_valid = true" v-if="checked_valid === false" class="edit d_block">편집</div>
					<div v-if="checked_valid === true" class="completion">
						<ul>
							<li v-if="all_ck_valid === false" @click="check_all()">전체선택</li>
							<li v-if="all_ck_valid === true" @click="check_all_cancle()">선택해제</li>
							<li @click="checked_valid = false">편집취소</li>
							<li @click="check_delete()">삭제완료</li>
						</ul>
					</div>
				</div>
				<div v-for="(item, index) in coupon_list" :key="index" class="mypage_list_box">
					<input v-if="checked_valid === true" id="coupon_check_test" type="checkbox" />
					<ul class="tit_wrap2">
						<li class="bold line_h">쿠폰명</li>
						<li class="bold line_h">사용 기간</li>
						<li class="bold line_h">상태</li>
					</ul>
					<ul class="info_wrap">
						<li class="line_h">{{ item.c_title }}</li>
						<li class="line_h">{{ item.start_date }} ~ {{ item.end_date }}</li>
						<li class="line_h">{{ use_state }}</li>
					</ul>
				</div>
				<div v-if="jwt.getToken('id_token')" class="pagenation_wrap">
					<ul class="pagenation">
						<Pagination
							v-if="coupon_list.length > 0"
							@changePage="page_active"
							:page="page"
							:totalPage="totalPage"
						></Pagination>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Pagination from '@/shared-components/Pagination.vue';
import ajax from '@/api/api-manager';

const coupon_list = ref([]);
const coupon_integer = ref(null);
const page = ref(null);
const totalPage = ref(null);
const current_btn_valid = ref(1);
const checked_valid = ref(false);
const all_ck_valid = ref(false);
const use_state = ref('');
import jwt from '@/api/LocalStorage';

onMounted(() => {
	if (jwt.getToken('id_token')) {
		coupon_list_paging();
	}
});

const check_all = () => {
	const checked_data = document.querySelectorAll('#coupon_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (p.checked = true), (all_ck_valid.value = true);
	});
};
const check_all_cancle = () => {
	const checked_data = document.querySelectorAll('#coupon_check_test');
	Array.from(checked_data).map((p, idx) => {
		return (all_ck_valid.value = false), (p.checked = false);
	});
};

const coupon_list_paging = () => {
	const page_num = current_btn_valid.value;
	ajax('mypage/coupon_list', page_num).then((res) => {
		console.log(res);
		res.data.data.map((p) => {
			if (p.issue_yn === 'Y') {
				use_state.value = '사용가능';
			} else if (p.issue_yn === 'N') {
				use_state.value = '만료';
			}
		}),
			(coupon_list.value = res.data.data);
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};
const page_active = (event) => {
	current_btn_valid.value = event;
	coupon_list_paging();
};

const coupon_regi = () => {
	const regi_form = new FormData();
	regi_form.append('num', coupon_integer.value);
	ajax('mypage/coupon_regi', regi_form)
		.then((res) => {
			console.log(res);
			if (res.data.data === '이미 사용한쿠폰입니다.') {
				alert('이미 사용한쿠폰입니다.');
			} else if (res.data.data === '쿠폰 등록이 완료되었습니다.') {
				alert('쿠폰 등록이 완료되었습니다.');
			} else if (res.data.data === '만료된 쿠폰입니다.') {
				alert('만료된 쿠폰입니다.');
			}
		})
		.catch((err) => {
			if (err.response.data.status !== 403) alert('쿠폰번호를 확인해주세요!');
		});
};

// 체크 후 삭제 버튼 클릭 시
const check_delete = () => {
	const checked_data = document.querySelectorAll('#coupon_check_test');

	if (confirm('선택하신 쿠폰들을 삭제하시겠습니까? (복구 불가능)')) {
		Array.from(checked_data).map((p, idx) => {
			if (p.checked) {
				delete_like_list(idx);
			}
		});
	} else {
		return false;
	}
};

// 체크된 항목들을 리스트에서 삭제함
const delete_like_list = (index) => {
	const form = new FormData();
	form.append('cNo', coupon_list.value[index].c_no);

	ajax('mypage/coupon_content_list', form).then((res) => {
		coupon_list_paging();
		const checked_data = document.querySelectorAll('#coupon_check_test');
		Array.from(checked_data).map((p, idx) => {
			return (p.checked = false);
		});
	});
};
</script>
